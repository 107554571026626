import React from "react";
import { RenderSvg } from "./fragments/constant";

export const StartEvent = () => {
  return (
    <div className="start-event-page">
      <div className="start-event-box">
        <div className="start-event-title_desc">
          <h2 className="start-event-title">Choose event type!</h2>
        </div>
        <div>
          <RenderSvg />
        </div>
      </div>
    </div>
  );
};
