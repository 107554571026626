import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { EmailValidator, FindError, ProfileInfo, useModal } from "utils";
import { useForm } from "react-hook-form";
import { bankAccountActions, httpRequestsOnErrorsActions } from "store";

import {
  AddressInput,
  ButtonMain,
  CancelBtn,
  DateCalendarForm,
  InputMain,
  PhoneInput,
} from "components";
import { BUSINESSTYPE, CONNECT_NON_PROFIT } from "../constants";

export const PersonalDetails = () => {
  const dispatch = useDispatch();
  const { close } = useModal();
  const { handleSubmit, control, clearErrors, setError } = useForm({});
  const personBeckError = FindError(CONNECT_NON_PROFIT);
  const { myProfile } = ProfileInfo();

  useEffect(() => {
    return () => {
      clearErrors();
      dispatch(httpRequestsOnErrorsActions.removeError(CONNECT_NON_PROFIT));
    };
  }, []);

  useEffect(() => {
    if (
      personBeckError?.error === "Invalid address format or location not found."
    ) {
      setError("address", {
        message: "Invalid address format or location not found.",
      });
    }

    if (
      personBeckError?.error === "Something went wrong while converting address"
    ) {
      setError("address", {
        message: "Something went wrong while converting address.",
      });

      dispatch(httpRequestsOnErrorsActions.removeError(CONNECT_NON_PROFIT));
    }
  }, [personBeckError]);

  const handleCreate = (data) => {
    const dob = moment.utc(data?.birthDate);

    const sandData = {
      email: myProfile?.email,
      businessType: BUSINESSTYPE,
      individual: {
        ...data,
        phone: parseInt(data.phone.replace(/[^0-9]/g, "")),
        address: data?.address,
        dob: {
          day: dob.date(),
          month: dob.month() + 1,
          year: dob.year(),
        },
      },
    };

    delete sandData?.individual?.birthDate;

    dispatch(bankAccountActions.connectNonProfit(sandData));
  };

  return (
    <div className="settings-connecting-bank-wrapper settings-personal-detail">
      <div className="settings-connecting-bank-header">
        <p>Personal Details</p>
      </div>
      <form onSubmit={handleSubmit(handleCreate)}>
        <div className="settings-connecting-bank-body">
          <InputMain
            name="firstName"
            control={control}
            placeholder="Enter your first name"
            labelHeader="First Name"
            rules={{
              required: "First name is required",
              validate: {
                trimmed: (value) =>
                  value.trim() !== "" ||
                  "First name cannot be empty or just spaces",
              },
            }}
          />

          <InputMain
            name="lastName"
            control={control}
            placeholder="Enter your last name"
            labelHeader="Last Name"
            rules={{
              required: "Last name is required",
              validate: {
                trimmed: (value) =>
                  value.trim() !== "" ||
                  "Last name cannot be empty or just spaces",
              },
            }}
          />

          <DateCalendarForm
            name="birthDate"
            placeholder="dd/mm/yyyy"
            control={control}
            label="Date of Birth*"
            rules={{
              required: "Date of Birth is required",
              validate: (value) => {
                const selectedDate = moment(value);
                const currentDate = moment();
                const age = currentDate.diff(selectedDate, "years");
                return (
                  age >= 13 ||
                  "You must be at least 13 years old to use Stripes"
                );
              },
            }}
          />

          <InputMain
            name="email"
            type="email"
            control={control}
            placeholder="Enter your personal email address"
            labelHeader="Email Address"
            rules={{
              required: "Email Address is required",
              pattern: {
                value: EmailValidator,
                message: "Must be valid email",
              },
            }}
          />

          <PhoneInput
            placeholder="Enter your contact number"
            name="phone"
            control={control}
            labelHeader="Phone Number"
            rules={{
              required: "Phone Number is required",
              minLength: {
                value: 10,
                message: "Please enter a valid 10 digit number.",
              },
              validate: (value) => {
                return (
                  value?.replace(/[^0-9]/g, "").length === 10 ||
                  "Please enter a valid 10 digit number."
                );
              },
            }}
          />

          {/*<InputMain*/}
          {/*  name="idNumber"*/}
          {/*  control={control}*/}
          {/*  labelHeader="SSN"*/}
          {/*  placeholder="Social Security Number"*/}
          {/*  type="number"*/}
          {/*  maxLength={9}*/}
          {/*  rules={{*/}
          {/*    required: "SSN is required",*/}
          {/*    minLength: {*/}
          {/*      value: 9,*/}
          {/*      message: "Please enter a valid 9 digit number.",*/}
          {/*    },*/}
          {/*  }}*/}
          {/*/>   */}

          <InputMain
            name="last4SSN"
            control={control}
            labelHeader="Last 4 Digits of SSN"
            placeholder="Enter last 4 digits of your SSN"
            type="number"
            maxLength={4}
            rules={{
              required: "Last 4 Digits of SSN is required",
              minLength: {
                value: 4,
                message: "Please enter a valid 4 digit number.",
              },
            }}
          />
          <AddressInput
            name="address"
            control={control}
            labelHeader="Address"
            placeholder="Address"
            rules={{
              required: "Address is required",
            }}
          />

          {personBeckError?.error &&
            personBeckError?.error !==
              "Invalid address format or location not found." &&
            personBeckError?.error !==
              "Something went wrong while converting address" && (
              <p className="error-message" style={{ fontSize: 14 }}>
                {personBeckError?.error}
              </p>
            )}
        </div>

        <div className="settings-connecting-bank-buttons">
          <CancelBtn
            text={"Cancel"}
            closeFunction={() => {
              close();
            }}
          />
          <ButtonMain
            actionTye={CONNECT_NON_PROFIT}
            type={"submit"}
            text={"Next"}
          />
        </div>
      </form>
    </div>
  );
};
