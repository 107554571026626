import { terms } from "./constants";

export const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions-page">
      <p className="terms-and-conditions-title">Terms & Conditions</p>
      <div className="terms-and-conditions-box">
        <p>
          Please read these terms and conditions carefully before using Our
          Service.
        </p>
        <br />
        <ol className="terms-lists-block">
          {terms.map((term, index) => (
            <li className="terms-lists-item" key={index}>
              <b>{term?.boldText}</b> <span>{term?.text}</span>
              {!!term?.list?.length && (
                <ul className="terms-sub-lists">
                  {term?.list.map((list, index) => (
                    <li key={index}>{list?.text}</li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};
