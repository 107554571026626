import { Svg } from "assets";

export const NotYet = ({ height }) => {
  return (
    <div className="not-yet-section" style={height ? { height: height } : {}}>
      <Svg.NotYet className="not-yet-section-svg" />
      <h1>No Events Found</h1>
      {/* <p>It looks like you don't have any data yet.</p> */}
    </div>
  );
};
