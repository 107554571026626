import React from "react";
import { useLocation, useNavigate } from "react-router";
import { Pagination } from "@mui/material";
import { SendPageSave } from "utils";

export const PaginationItem = ({ count, list }) => {
  const limitCountNumber = 10;
  const navigate = useNavigate();
  const location = useLocation();
  const info = location?.state;
  const page = info?.page;

  const firsCountNumber = page
    ? page <= 1
      ? 1
      : (page - 1) * limitCountNumber + 1
    : 1;
  const lastCount = page ? +((page - 1) * 100) + list?.length : list?.length;

  const handleChangePage = (val) => {
    window.scrollTo(0, 0);
    SendPageSave(navigate, val, info, location?.pathname);
  };

  return (
    <div className="pagination-wrapper">
      <div className="pagination-showing-count">
        <p>
          {`Showing ${firsCountNumber ? firsCountNumber : 0} to ${
            lastCount ? lastCount : 0
          } of ${count ? count : 0} entries`}
        </p>
      </div>
      <Pagination
        // disabled={loader}
        onChange={(event, val) => handleChangePage(val)}
        page={page}
        count={count && Math.ceil(count / limitCountNumber)}
        color={"primary"}
      />
    </div>
  );
};
