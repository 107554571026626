import React from "react";
import { Svg } from "assets";

export const DrawerOpening = ({ onClick, title }) => {
  return (
    <button className="drawer_opening_main_button " onClick={onClick}>
      <p>{title}</p>

      <Svg.ArrowDown />
    </button>
  );
};
