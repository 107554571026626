import React from "react";

export const FundraiserDetailsCard = ({
  title,
  list,
  onClick,
  headerColor,
  titleColor,
  borderColor,
}) => {
  return (
    <div
      className="fundraiser-details-card-wrapper"
      style={{ border: `${borderColor ? "1px solid" + borderColor : ""}` }}
      onClick={onClick}
    >
      <div
        className="fundraiser-details-card-header"
        style={{ background: headerColor ? headerColor : "" }}
      >
        <p style={{ color: `${titleColor ? titleColor : ""}` }}>{title}</p>
      </div>
      {!!list?.length && (
        <div className="fundraiser-details-card-content">
          {list.map((i, k) => (
            <div key={k} className="fundraiser-details-single-card">
              <p>{i?.name}</p>
              <div>{i?.description}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
