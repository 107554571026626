import React, { forwardRef } from "react";
import { InputAdornment, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import moment from "moment";

export const InputMain = forwardRef(
  (
    {
      className,
      label,
      labelHeader,
      placeholder,
      rules,
      control,
      name,
      type = "text",
      maxLength,
      startAdornment,
      endAdornment,
      onClickIconStart,
      onClickIconEnd,
      showPassword,
      disabled,
      min,
      max,
      ...anotherProps
    },
    ref
  ) => {
    const EndIcon = showPassword ? Visibility : VisibilityOff;
    const typePassword = showPassword ? "text" : "password";

    const onLength = (e) => {
      if (maxLength) {
        return (e.target.value = e.target.value.slice(0, maxLength));
      }
    };

    return (
      <div
        className={`input-main-wrapper input-custom-wrapper ${
          className ? className : ""
        }`}
        ref={ref}
      >
        <Controller
          control={control}
          name={name}
          rules={rules}
          render={({ field, formState: { errors } }) => {
            return (
              <>
                {labelHeader && (
                  <p
                    className={`input-main-label-header ${
                      errors[field?.name]?.message ? "errorLabel" : ""
                    }`}
                  >
                    {labelHeader}
                    {rules?.required && (
                      <span className="input-main-label-header-required">
                        *
                      </span>
                    )}
                  </p>
                )}
                <TextField
                  onInput={(e) => onLength(e)}
                  disabled={disabled}
                  ref={ref}
                  {...anotherProps}
                  {...field}
                  onWheel={(e) => {
                    if (type === "number") {
                      e?.preventDefault();
                      e.target.blur();
                    }
                  }}
                  onChange={(event) => {
                    if (+event.target.value < 0) {
                      event.target.value = 0;
                    }
                    field?.onChange(event);
                  }}
                  autoComplete="off"
                  error={!!errors[field?.name]?.message}
                  type={showPassword ? typePassword : type}
                  value={field.value}
                  placeholder={placeholder}
                  label={label}
                  inputProps={{
                    min: min ? moment.utc(min).format("YYYY-MM-DD") : "",
                    max: max ? max : "",
                    maxLength: maxLength ? maxLength : "",
                  }}
                  InputProps={{
                    startAdornment: startAdornment && (
                      <InputAdornment
                        onClick={onClickIconStart}
                        className={`${
                          errors[field?.name]?.message ? "error-svg" : ""
                        }`}
                        position="start"
                      >
                        {startAdornment}
                      </InputAdornment>
                    ),
                    endAdornment:
                      (endAdornment && (
                        <InputAdornment
                          onClick={onClickIconEnd}
                          position="end"
                          className={`${
                            errors[field?.name]?.message ? "error-svg" : ""
                          }`}
                        >
                          {endAdornment}
                        </InputAdornment>
                      )) ||
                      (type === "password" && (
                        <InputAdornment
                          onClick={onClickIconEnd}
                          position="end"
                          className={`${
                            errors[field?.name]?.message
                              ? "error-svg-password"
                              : ""
                          }`}
                        >
                          <EndIcon />
                        </InputAdornment>
                      )),
                  }}
                />
                <p className="error-message">{errors[field?.name]?.message}</p>
              </>
            );
          }}
        />
      </div>
    );
  }
);

InputMain.displayName = "InputMain";
