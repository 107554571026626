import { ButtonMain, CancelBtn } from "../buttons";

export const ModalActions = ({
  saveTitle,
  type,
  handleClick,
  loadAction,
  defaultStyle,
  closeFunction,
}) => {
  return (
    <div className={defaultStyle ? "default-actions-box" : "modal-actions-box"}>
      <CancelBtn closeFunction={closeFunction} />
      <ButtonMain
        type={type}
        onClick={handleClick}
        text={saveTitle}
        actionTye={loadAction}
      />
    </div>
  );
};
