import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ButtonMain, InputMain, PhoneInput } from "components";
import { EmailValidator, FindError, FindSuccess, useModal } from "utils";
import {
  authActions,
  httpRequestsOnErrorsActions,
  httpRequestsOnSuccessActions,
} from "store";
import { Svg } from "assets";
import { AuthModalHeaders, IfRemember } from "./fragments";
import { WelcomeAboard } from "./welcomeAboard";

const ACTION_TYPE = "SIGN_UP";

export const SignUp = ({
  classNameWrapper,
  classNameContent,
  classNameInput,
  classNameButton,
  onLoginAccount,
}) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const backError = FindError(ACTION_TYPE);
  const success = FindSuccess(ACTION_TYPE);
  const [showPasswords, setShowPasswords] = useState({
    password: false,
    confirmPassword: false,
  });
  const { handleSubmit, control, setError, reset } = useForm({});

  useEffect(() => {
    reset();
  }, []);

  useEffect(() => {
    if (success) {
      openModal(<WelcomeAboard />);
      dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
    }
  }, [success]);

  useEffect(() => {
    dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
    if (backError?.error === "Such user does not exist in our records") {
      setError("email", {
        type: "required",
        message: "Such user does not exist in our records",
      });
    }
    if (backError?.error === "User with this email address already exists") {
      setError("email", {
        type: "required",
        message: "User with this email exists",
      });
    }
    if (backError?.error === "User with this username already exists") {
      setError("username", {
        type: "required",
        message: "User with this username already exists",
      });
    }
    if (backError?.error === "User password does not match") {
      setError("password", {
        type: "required",
        message: "User password does not match",
      });
    }
  }, [backError, dispatch]);

  const onSubmit = (data) => {
    if (data?.password === data?.confirmPassword) {
      const params = {
        ...data,
      };

      if (data?.phoneNumber) {
        params.phoneNumber = parseInt(data?.phoneNumber.replace(/[^0-9]/g, ""));
      } else {
        delete params.phoneNumber;
      }
      dispatch(authActions.signUp(params));
    } else {
      setError("confirmPassword", {
        type: "required",
        message: "Password does not match with the confirmation",
      });
    }
  };

  const handleClickIcon = (name) => {
    const params = {
      ...showPasswords,
    };
    params[name] = !params[name];
    setShowPasswords(params);
  };

  return (
    <div className={`auth-modals ${classNameWrapper ? classNameWrapper : ""}`}>
      <AuthModalHeaders title={"Create Account"} />
      <form
        className={`form-box ${classNameContent ? classNameContent : ""}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputMain
          className={classNameInput ? classNameInput : ""}
          name="firstName"
          type="text"
          control={control}
          placeholder="First Name"
          labelHeader="First Name"
          maxLength={20}
          rules={{
            required: "This Field is required",
            // minLength: {
            //   value: 3,
            //   message:
            //     "First name must be longer than or equal to 3 characters",
            // },
          }}
          startAdornment={<Svg.Profile />}
        />

        <InputMain
          className={classNameInput ? classNameInput : ""}
          name="lastName"
          type="text"
          control={control}
          placeholder="Last Name"
          labelHeader="Last Name"
          maxLength={20}
          rules={{
            required: "This Field is required",
          }}
          startAdornment={<Svg.Profile />}
        />
        <InputMain
          className={classNameInput ? classNameInput : ""}
          name="username"
          type="text"
          control={control}
          placeholder="User Name"
          labelHeader="User Name"
          maxLength={20}
          rules={{
            required: "This Field is required",
          }}
          startAdornment={<Svg.Profile />}
        />
        <InputMain
          className={classNameInput ? classNameInput : ""}
          name="email"
          type="email"
          control={control}
          placeholder="Email"
          labelHeader="Email"
          rules={{
            required: "This Field is required",
            pattern: {
              value: EmailValidator,
              message: "Must be valid email",
            },
          }}
          startAdornment={<Svg.Sms />}
        />
        <PhoneInput
          className={classNameInput ? classNameInput : ""}
          placeholder={"ex (000) 000-0000"}
          name="phoneNumber"
          control={control}
          labelHeader="Phone Number"
        />
        <InputMain
          className={classNameInput ? classNameInput : ""}
          name="password"
          type="password"
          showPassword={showPasswords.password}
          control={control}
          placeholder="Password"
          labelHeader="Create Password"
          rules={{
            required: "This Field is required",
          }}
          onClickIconEnd={() => handleClickIcon("password")}
          startAdornment={<Svg.Lock />}
        />
        <InputMain
          className={classNameInput ? classNameInput : ""}
          name="confirmPassword"
          type="password"
          showPassword={showPasswords.confirmPassword}
          control={control}
          placeholder="Confirm Password"
          labelHeader="Confirm Password"
          rules={{
            required: "This Field is required",
          }}
          onClickIconEnd={() => handleClickIcon("confirmPassword")}
          startAdornment={<Svg.Lock />}
        />

        <p className="terms-of-use">
          By clicking the <span> “Create Account”</span> button, agree to the{" "}
          <a href="/terms" target="_blank">
            Terms
          </a>{" "}
          and{" "}
          <a href="/privacy" target="_blank">
            Conditions and the Privacy Policy
          </a>
        </p>
        <ButtonMain
          classNameWrapper={classNameButton ? classNameButton : ""}
          actionTye={ACTION_TYPE}
          text="Create Account"
          type="submit"
        />
        <IfRemember onLoginAccount={onLoginAccount} />
      </form>
    </div>
  );
};
