export const terms = [
  {
    text:
      'Welcome to MyWayFund.com ("the website," "we," "us," or "our"). By using our website, you ("the user," "you") agree to comply with and be bound by the following terms and conditions. Please review these terms carefully, and if you do not agree to these terms, you should not use this site.',
    boldText: "Introduction: ",
  },

  {
    text:
      "By using MyWayFund, you confirm that you are at least 18 years of age or have reached the age of majority in your jurisdiction. You also affirm that you are legally capable of entering into binding contracts and that all information you provide is truthful and accurate.",
    boldText: "Eligibility: ",
  },

  {
    text:
      "To create campaigns or donate, you will be required to create an account. You are responsible for maintaining the confidentiality of your login information and for any activity that occurs under your account. You agree to notify us immediately of any unauthorized use or breach of security of your account.",
    boldText: "User Accounts: ",
  },

  {
    text:
      "MyWayFund allows users to create campaigns to raise funds for personal, charitable, or other purposes that comply with these terms. By creating a campaign, you agree:",
    boldText: "Fundraising Campaigns: ",

    list: [
      {
        text: "Misleading or fraudulent fundraising.",
      },

      {
        text:
          "Using the platform for illegal activities, hate speech, or discrimination.",
      },

      {
        text:
          "Violating the rights of others, including intellectual property rights.",
      },

      {
        text: "Posting harmful or offensive content.",
      },
    ],
  },

  {
    text:
      "Donors may contribute to campaigns by making payments through the platform. By donating, you agree that:",
    boldText: "Donations: ",

    list: [
      {
        text: "Donations are made voluntarily.",
      },

      {
        text: "We do not guarantee how campaign owners will use the funds.",
      },

      {
        text:
          "All donations are final and non-refundable unless otherwise stated by us.",
      },
    ],
  },

  {
    text:
      "To create campaigns or donate, you will be required to create an account. You are responsible for maintaining the confidentiality of your login information and for any activity that occurs under your account. You agree to notify us immediately of any unauthorized use or breach of security of your account.",
    boldText: "User Accounts: ",
  },

  {
    text: "Users agree not to engage in any of the following activities:",
    boldText: "Donations: ",

    list: [
      {
        text: "Misleading or fraudulent fundraising.",
      },

      {
        text:
          "Using the platform for illegal activities, hate speech, or discrimination.",
      },

      {
        text:
          "Violating the rights of others, including intellectual property rights.",
      },

      {
        text: "Posting harmful or offensive content.",
      },
    ],
  },

  {
    text:
      "MyWayFund reserves the right, but is not obligated, to verify the information provided by campaign creators or to monitor the use of donations. We are not responsible for ensuring that funds are used as described by campaign creators.",
    boldText: "Campaign Verification and Monitoring: ",
  },

  {
    text:
      "MyWayFund is not liable for any indirect, consequential, exemplary, or punitive damages resulting from your use of the platform. Our total liability to you for any claim arising out of or relating to these terms is limited to the fees paid by you to MyWayFund for the use of the platform.",
    boldText: "Limitation of Liability: ",
  },

  {
    text:
      "You agree to indemnify and hold MyWayFund harmless from any claims, losses, liabilities, damages, costs, or expenses arising out of your violation of these terms, your misuse of the platform, or your violation of any rights of another person or entity.",
    boldText: "Indemnification: ",
  },

  {
    text:
      "MyWayFund reserves the right to modify these terms at any time. Changes will be effective immediately upon posting on the website. Continued use of the website after any such changes constitutes your acceptance of the revised terms.",
    boldText: "Changes to the Terms: ",
  },

  {
    text:
      "These terms shall be governed by and construed in accordance with the laws of the state of Californian, United states of America, without regard to its conflict of law provisions.",
    boldText: "Governing Law: ",
  },

  {
    text:
      "If you have any questions about these Terms, please contact us at info@mywayfund.com.",
    boldText: "Contact Us: ",
  },
];
