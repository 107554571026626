import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "nuka-carousel";
import { ButtonMain, PriceConvertor } from "components";
import { Images, Svg } from "assets";
import { JoinMovementCard } from "./joinMovementCard";
import { fundraiserStatuses } from "../../fundraisers";
import { fundraiserActions } from "store";

export const JoinMovement = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { fundraisers } = useSelector((state) => ({
    fundraisers: state.fundraiser.fundraisers,
  }));

  const firstFundraisers = fundraisers?.fundraisers?.length
    ? fundraisers?.fundraisers[0]
    : null;

  useEffect(() => {
    const params = {
      statuses: [fundraiserStatuses.LIVE],
    };

    dispatch(fundraiserActions.getFundraisers(params));
  }, [dispatch]);

  return (
    <div className="join-movement-wrapper">
      <div className="container">
        <div className="join-movement-header">
          <h2 className="join-movement-title">Join the Movement</h2>

          <ButtonMain
            classNameWrapper="ready-card-button-tablet"
            ClassName="ready-card-button"
            text={"Find More"}
            onClick={() => {
              navigate("/find-event");
            }}
            endIcon={<Svg.ArrowRightBlack />}
          />
        </div>

        <div className="join-movement-content-desktop">
          {firstFundraisers && (
            <JoinMovementCard
              classNameWrapper="join-movement-content-left-card"
              item={{
                ...firstFundraisers,
                img: firstFundraisers?.images?.length
                  ? firstFundraisers?.images[
                      firstFundraisers?.mainImage
                        ? firstFundraisers?.mainImage
                        : 0
                    ]?.url
                  : Images.needCardFrameWhite,
                title: firstFundraisers?.name,
                price: PriceConvertor({ price: firstFundraisers?.balance }),
                percentage: "52%",
                description: "",
              }}
            />
          )}

          <div className="join-movement-content-right-cards">
            {!!fundraisers?.fundraisers?.length &&
              fundraisers?.fundraisers?.slice(1, 5)?.map((item, index) => (
                <JoinMovementCard
                  classNameWrapper="right-single-card"
                  item={{
                    ...item,
                    img: item?.images?.length
                      ? item?.images[item?.mainImage]?.url
                      : Images.needCardFrameWhite,
                    title: item?.name,
                    price: PriceConvertor({ price: item?.balance }),
                    percentage: "52%",
                    description: "",
                  }}
                  key={index}
                />
              ))}
          </div>
        </div>

        <Carousel
          className="join-movement-carousel-wrapper"
          showArrows={true}
          slidesToShow={2}
          scrollDistance={"slide"}
          cellAlign="center"
        >
          {!!fundraisers?.fundraisers?.length &&
            fundraisers?.fundraisers?.map((item, index) => (
              <JoinMovementCard
                classNameWrapper="join-movement-carousel-card"
                item={{
                  ...item,
                  img: item?.images?.length
                    ? item?.images[item?.mainImage ? item?.mainImage : 0]?.url
                    : Images.needCardFrameWhite,
                  title: item?.name,
                  price: PriceConvertor({ price: item?.balance }),
                  percentage: "52%",
                  description: "",
                }}
                key={index}
              />
            ))}
        </Carousel>

        <ButtonMain
          classNameWrapper="ready-card-button-mobile"
          text={"Find More"}
          onClick={() => {
            navigate("/find-event");
          }}
          endIcon={<Svg.ArrowRightBlack />}
        />
      </div>
    </div>
  );
};
