import { useDispatch, useSelector } from "react-redux";
import {
  ButtonMain,
  DeleteButton,
  DeleteModal,
  DetailPageWrapper,
  EditBtn,
  PriceConvertor,
  ShareBtn,
  TitleValue,
  ToolTipRow,
  Upload,
  DateConvertor,
} from "components";
import { Svg } from "assets";
import { fundraiserStatuses } from "../../fundraisers";
import { useModal } from "utils";
import { EndEventModal } from "./modals";
import { DELETE_FUNDRAISER_ACTION, eventTypes } from "./constants";
import { fundraiserActions } from "store";

export const FundraiserInfo = ({ setEditMode }) => {
  const { fundraiserById } = useSelector((state) => ({
    fundraiserById: state.fundraiser.fundraiserById,
  }));

  const dispatch = useDispatch();
  const { openModal } = useModal();

  const handleUpload = (item) => {
    const params = {
      ...fundraiserById,
      mainImage: item?.mainImage,
    };
    delete params.charities;
    delete params.owner;
    params.imagesToAdd = [item?.file];
    dispatch(
      fundraiserActions.editFundraiser(fundraiserById?.id, params, "noLoad")
    );
  };

  const handleSetMain = (main) => {
    const params = {
      ...fundraiserById,
    };
    delete params.charities;
    params.mainImage = main;
    dispatch(
      fundraiserActions.editFundraiser(fundraiserById?.id, params, "noLoad")
    );
  };

  const handleDeleteImage = (item) => {
    const params = {
      ...fundraiserById,
    };
    delete params.charities;
    params.imagesToRemove = [item];
    dispatch(
      fundraiserActions.editFundraiser(fundraiserById?.id, params, "noLoad")
    );
  };
  const endEvent = () => {
    openModal(
      <EndEventModal
        customIcon={<Svg.DeleteCharityMemory />}
        id={fundraiserById?.id}
      />,
      {
        padding: "noPaddings",
        noShowCloseBrn: true,
      }
    );
  };

  return (
    <div className="fundraiser-detail-screen-wrapper">
      <DetailPageWrapper
        head={
          <>
            <p className="detail-page-title">Basic Information</p>
            <div className="fundraiser-detail-actions">
              {fundraiserById?.status !== fundraiserStatuses?.COMPETED && (
                <EditBtn handleClick={() => setEditMode(true)} />
              )}

              <div className="share-or-publish-box">
                {fundraiserById?.status !== fundraiserStatuses?.COMPETED && (
                  <ButtonMain
                    onClick={endEvent}
                    text="End Event"
                    startIcon={<Svg.PauseCircleSvg />}
                  />
                )}

                <ShareBtn
                  fill={"#34A853"}
                  background={"#F4F4F4"}
                  classNameButton="events_share-btn"
                />
              </div>
            </div>
          </>
        }
        body={
          <div>
            <div className="event-information-wrapper">
              <div className="event-information-single person-details">
                <p className="event-information_title">Person Details</p>
                <TitleValue
                  title="First Name"
                  value={fundraiserById?.firstName}
                />
                <TitleValue
                  title="Last  Name"
                  value={fundraiserById?.lastName}
                />
                <TitleValue
                  title="Middle Initial"
                  value={fundraiserById?.middleName}
                />
                {fundraiserById?.celebrationDate && (
                  <TitleValue
                    title="Date of Celebration"
                    value={DateConvertor(fundraiserById?.celebrationDate)}
                  />
                )}

                {fundraiserById?.birthDate && (
                  <TitleValue
                    title="Date of Birth"
                    value={DateConvertor(fundraiserById?.birthDate)}
                  />
                )}

                {fundraiserById?.deathDate && (
                  <TitleValue
                    title="Date of Death"
                    value={DateConvertor(fundraiserById?.deathDate)}
                  />
                )}
              </div>

              <div className="event-information-single event-details">
                <p className="event-information_title">Event Details</p>
                <TitleValue
                  title="Event Name"
                  value={fundraiserById?.firstName}
                />
                <TitleValue
                  title="Created Date"
                  value={DateConvertor(fundraiserById?.createdAt)}
                />
                {fundraiserById?.endDate && (
                  <TitleValue
                    title="End Date"
                    value={DateConvertor(fundraiserById?.endDate)}
                  />
                )}

                <TitleValue
                  title="Type"
                  value={eventTypes[fundraiserById?.type]}
                />

                <TitleValue
                  title="Fund Type"
                  value={`Split ${100 - +fundraiserById?.keepPercent}% Keep ${
                    fundraiserById?.keepPercent
                  }%`}
                />
                <TitleValue
                  title="Balance"
                  value={<PriceConvertor price={fundraiserById?.balance} />}
                />
              </div>
            </div>

            {!!fundraiserById?.charities?.length && (
              <div className="assigned-charities-wrapper">
                <p className="assigned-charities-title">Assigned Charities</p>

                <div className="assigned-charities-list">
                  {fundraiserById?.charities?.map(({ charity }, index) => (
                    <p key={index} className="assigned-charity-single">
                      {charity?.name}
                    </p>
                  ))}
                </div>
              </div>
            )}

            {fundraiserById?.description && (
              <div className="fundraiser-detail-description">
                <p>Description</p>
                <span>{fundraiserById?.description}</span>
              </div>
            )}

            <div className="share-or-publish-mobile-box">
              {fundraiserById?.status !== fundraiserStatuses?.COMPETED && (
                <ButtonMain
                  onClick={endEvent}
                  text="End Event"
                  startIcon={<Svg.PauseCircleSvg />}
                />
              )}

              <ShareBtn
                fill={"#000624"}
                background={"#F4F4F4"}
                classNameButton="events_share-btn"
                buttonText="Copy Link"
              />
            </div>
          </div>
        }
      />

      <DetailPageWrapper
        head={
          <div>
            <p className="detail-page-title">Add Photo</p>
          </div>
        }
        body={
          <Upload
            handleSetMain={handleSetMain}
            handleChange={handleUpload}
            handleDelete={handleDeleteImage}
            backImages={fundraiserById}
          />
        }
      />

      <DetailPageWrapper
        body={
          <div className="space-between">
            <DeleteButton
              disabled={!fundraiserById?.canDelete}
              label={"Delete Event"}
              handleClick={() => {
                openModal(
                  <DeleteModal
                    customIcon={<Svg.DeleteCharityMemory />}
                    title={"Are you sure you want to Delete event?"}
                    description={
                      "This action cannot be undone. Deleting this event will permanently remove all associated data."
                    }
                    handleDelete={() =>
                      dispatch(
                        fundraiserActions.deleteFundraiser(fundraiserById?.id)
                      )
                    }
                    actionType={DELETE_FUNDRAISER_ACTION}
                  />,
                  {
                    padding: "noPaddings",
                    noShowCloseBrn: true,
                  }
                );
              }}
            />

            {!fundraiserById?.canDelete && (
              <ToolTipRow
                text={
                  "If you wish to delete a event, ensure it has reached its completion status. Active fundraisers cannot be deleted. Thank you for your understanding .t"
                }
              />
            )}
          </div>
        }
      />
    </div>
  );
};
