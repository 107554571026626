import React from "react";
import { useNavigate } from "react-router";
import { Svg } from "assets";
import { ButtonMain } from "../buttons";

export const PaymentSuccess = () => {
  const navigate = useNavigate();

  const colorStyles = {
    color: "#000",
  };

  return (
    <div className="payment-success-wrapper">
      <div className="payment-success-box" style={{ background: "white" }}>
        <Svg.PaymentSuccess />
        <div>
          <p style={colorStyles} className="payment-success-title">
            Payment Successful!
          </p>
          <p style={colorStyles} className="payment-success-desc">
            Your donation has been successfully done! Thank you for your
            generous contribution.Together, we are creating a positive impact.{" "}
          </p>
        </div>
        <ButtonMain
          buttonMainStyles={{
            background: "#34A853",
          }}
          textStyles={{
            color: "white",
          }}
          text="Go to Homepage"
          onClick={() => navigate("/")}
        />
      </div>
    </div>
  );
};
