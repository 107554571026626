import React from "react";
import { Svg } from "assets";

export const HeaderDrawerMain = ({ closeModal, title }) => {
  return (
    <div className="header-drawer-main-wrapper">
      <p> {title}</p>
      <button onClick={closeModal}>
        <Svg.Close />
      </button>
    </div>
  );
};
