import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { Calendar } from "react-date-range";
import moment from "moment";
import { DateInput } from "rsuite";
import { enGB } from "date-fns/locale";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { Svg } from "assets";
import { Popper } from "@mui/material";

export const DateCalendarForm = ({
  label,
  control,
  name,
  rules,
  max,
  min,
  disabled,
}) => {
  const [isFocused, setFocused] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const customLocale = {
    ...enGB,
    options: {
      ...enGB.options,
      weekStartsOn: 1,
    },
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const getNestedError = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  const isValidDate = (dateString) => {
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  };

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, formState: { errors } }) => {
        const error = getNestedError(errors, name);
        return (
          <>
            <div className="date-calendar-wrapper">
              {label && (
                <p
                  style={
                    disabled
                      ? { color: "rgba(0, 0, 0, 0.26)" }
                      : error
                      ? { color: "#D03325" }
                      : field?.value
                      ? { color: "#000624" }
                      : {}
                  }
                  className="date-input-title"
                >
                  {label}
                </p>
              )}

              <button
                disabled={disabled}
                onClick={handleClick}
                style={
                  disabled
                    ? { borderColor: "rgba(0, 0, 0, 0.26)" }
                    : error?.message
                    ? { borderColor: "#D03325", outlineColor: "#D03325" }
                    : {}
                }
                className={`date-calendar-main-button ${
                  isFocused ? "date-calendar-main-button-focused" : ""
                }`}
                aria-describedby={id}
                type={"button"}
              >
                <div
                  className="date-range-inputs"
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <DateInput
                    min={min}
                    max={max}
                    disabled={disabled}
                    placeholder={"mm/dd/yyyy"}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    format="MM/dd/yyyy"
                    value={field?.value ? moment(field?.value).toDate() : null}
                    onChange={(date) => {
                      const isValid = isValidDate(date);
                      const minDate = min ? new Date(min) : null;
                      const maxDate = max ? new Date(max) : null;
                      const isWithinRange =
                        (!minDate || date >= minDate) &&
                        (!maxDate || date <= maxDate);

                      if (isValid && isWithinRange) {
                        const formattedDate = moment(date).format("YYYY-MM-DD");
                        field.onChange(formattedDate);
                      }
                    }}
                  />
                </div>
                <div className="calendar-icon-clear-button">
                  {field?.value && (
                    <button
                      className="remove-calendar-day"
                      type={"button"}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        field.onChange(null);
                      }}
                    >
                      <Svg.Close />
                    </button>
                  )}

                  <Svg.CalendarSvg style={{ opacity: disabled ? 0.7 : 1 }} />
                </div>
              </button>

              <p className="error-message">{error?.message}</p>

              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className={"modal-custom-date-selector-box"}
                placement="bottom-start"
                style={{ position: "absolute", zIndex: 1400 }}
              >
                <Calendar
                  date={field?.value ? moment(field?.value).format() : null}
                  onChange={(date) => {
                    const formattedDate = moment(date).format("YYYY-MM-DD");
                    field.onChange(formattedDate);
                  }}
                  locale={customLocale}
                  maxDate={max ? new Date(max) : undefined}
                  minDate={min ? new Date(min) : undefined}
                />
                <button
                  type={"button"}
                  className="clear-bnt"
                  onClick={() => field.onChange(null)}
                >
                  Clear
                </button>
              </Popper>
              {open && (
                <div className="beck-drop" onClick={() => setAnchorEl(null)} />
              )}
            </div>
          </>
        );
      }}
    />
  );
};
