import { ReactComponent as AddBlackCircle } from "./images/svg/addBlackCircle.svg";
import { ReactComponent as AddWhiteCircle } from "./images/svg/addWhiteCircle.svg";
import { ReactComponent as Menu } from "./images/svg/menu.svg";
import { ReactComponent as ArrowRight } from "./images/svg/arrowRight.svg";
import { ReactComponent as DoneOlive } from "./images/svg/doneOlive.svg";
import { ReactComponent as Heart } from "./images/svg/heart.svg";
import { ReactComponent as Instagram } from "./images/svg/instagram.svg";
import { ReactComponent as Facebook } from "./images/svg/facebook.svg";
import { ReactComponent as Twitter } from "./images/svg/twitter.svg";
import { ReactComponent as Youtube } from "./images/svg/youtube.svg";
import { ReactComponent as FooterEmail } from "./images/svg/footerEmail.svg";
import { ReactComponent as FooterPhone } from "./images/svg/footerPhone.svg";
import { ReactComponent as Close } from "./images/svg/close.svg";
import { ReactComponent as SignIn } from "./images/svg/signIn.svg";
import { ReactComponent as SignInMemory } from "./images/svg/SignInMemory.svg";
import { ReactComponent as Sms } from "./images/svg/sms.svg";
import { ReactComponent as Lock } from "./images/svg/lock.svg";
import { ReactComponent as Recover } from "./images/svg/recover.svg";
import { ReactComponent as CheckEmail } from "./images/svg/checkEmail.svg";
import { ReactComponent as Welcome } from "./images/svg/welcome.svg";
import { ReactComponent as Profile } from "./images/svg/profile.svg";
import { ReactComponent as Call } from "./images/svg/call.svg";
import { ReactComponent as WelcomeAboard } from "./images/svg/welcomeAboard.svg";
import { ReactComponent as ProfileProperty } from "./images/svg/profileProperty.svg";
import { ReactComponent as ProfileLink } from "./images/svg/profileLink.svg";
import { ReactComponent as FundraisersLink } from "./images/svg/fundraisersLink.svg";
import { ReactComponent as SettingLink } from "./images/svg/settingLink.svg";
import { ReactComponent as CharitiesLink } from "./images/svg/charitiesLink.svg";
import { ReactComponent as Logout } from "./images/svg/logout.svg";
import { ReactComponent as Search } from "./images/svg/search.svg";
import { ReactComponent as NoFundraiserImage } from "./images/svg/noFundraiserImage.svg";
import { ReactComponent as NoUserImage } from "./images/svg/noUserImage.svg";
import { ReactComponent as BeckArrow } from "./images/svg/beckArrow.svg";
import { ReactComponent as Edit } from "./images/svg/edit.svg";
import { ReactComponent as Trash } from "./images/svg/trash.svg";
import { ReactComponent as NotUploaded } from "./images/svg/notUploaded.svg";
import { ReactComponent as ChevronRight } from "./images/svg/chevronRight.svg";
import { ReactComponent as EditBlueSvg } from "./images/svg/EditBlueSvg.svg";
import { ReactComponent as Share } from "./images/svg/share.svg";
import { ReactComponent as ShareIcon } from "./images/svg/shareIcon.svg";
import { ReactComponent as RedTrash } from "./images/svg/redTrash.svg";
import { ReactComponent as PlayCircle } from "./images/svg/playCircle.svg";
import { ReactComponent as PublishAsset } from "./images/svg/publishAsset.svg";
import { ReactComponent as NotYet } from "./images/svg/notYet.svg";
import { ReactComponent as NotYetMemory } from "./images/svg/NotYetMemory.svg";
import { ReactComponent as FundraiserFrameSvg } from "./images/svg/FundraiserFrameSvg.svg";
import { ReactComponent as FundraiserFrameMemorySvg } from "./images/svg/FundraiserFrameMemorySvg.svg";
import { ReactComponent as Google } from "./images/svg/google.svg";
import { ReactComponent as AppleSvg } from "./images/svg/AppleSvg.svg";
import { ReactComponent as Bank } from "./images/svg/bank.svg";
import { ReactComponent as TwitterShare } from "./images/svg/twitterShare.svg";
import { ReactComponent as LinkedinShare } from "./images/svg/linkedinShare.svg";
import { ReactComponent as InstagramShare } from "./images/svg/instagramShare.svg";
import { ReactComponent as FacebookShare } from "./images/svg/facebookShare.svg";
import { ReactComponent as CopyLink } from "./images/svg/copyLink.svg";
import { ReactComponent as RecentDonors } from "./images/svg/recentDonors.svg";
import { ReactComponent as ArrowRightBlack } from "./images/svg/arrowRightBlack.svg";
import { ReactComponent as ShareTransparent } from "./images/svg/shareTransparent.svg";
import { ReactComponent as PhoneWhiteSvg } from "./images/svg/PhoneWhiteSvg.svg";
import { ReactComponent as EmailWhite } from "./images/svg/EmailWhite.svg";
import { ReactComponent as CreditCard } from "./images/svg/creditCard.svg";
import { ReactComponent as Info } from "./images/svg/info.svg";
import { ReactComponent as Ellipse } from "./images/svg/ellipse.svg";
import { ReactComponent as ConnectedBank } from "./images/svg/connectedBank.svg";
import { ReactComponent as ImageUpload } from "./images/svg/imageUpload.svg";
import { ReactComponent as CheckBoxChecked } from "./images/svg/checkBoxChecked.svg";
import { ReactComponent as CheckboxNotChecked } from "./images/svg/checkBoxNotChecked.svg";
import { ReactComponent as CreateEventSvg } from "./images/svg/howItWork/CreateEventSvg.svg";
import { ReactComponent as CreateFreeSvg } from "./images/svg/howItWork/CreateFreeSvg.svg";
import { ReactComponent as FindAnEventSvg } from "./images/svg/howItWork/FindAnEventSvg.svg";
import { ReactComponent as ParticipateSvg } from "./images/svg/howItWork/ParticipateSvg.svg";
import { ReactComponent as SelectsCharitiesSvg } from "./images/svg/howItWork/SelectsCharitiesSvg.svg";
import { ReactComponent as ShareEventSvg } from "./images/svg/howItWork/ShareEventSvg.svg";
import { ReactComponent as LockSvg } from "./images/svg/LockSvg.svg";
import { ReactComponent as TrustedSvg } from "./images/svg/TrustedSvg.svg";
import { ReactComponent as EthicsSvg } from "./images/svg/EthicsSvg.svg";
import { ReactComponent as ShieldSvg } from "./images/svg/ShieldSvg.svg";
import { ReactComponent as EnvelopeSvg } from "./images/svg/EnvelopeSvg.svg";
import { ReactComponent as SearchSvg } from "./images/svg/SearchSvg.svg";
import { ReactComponent as CheckMarkSvg } from "./images/svg/CheckMarkSvg.svg";
import { ReactComponent as NotFoundSvg } from "./images/svg/NotFoundSvg.svg";
import { ReactComponent as InMemorySvg } from "./images/svg/InMemorySvg.svg";
import { ReactComponent as InCelebration } from "./images/svg/InCelebration.svg";
import { ReactComponent as WhiteArrowSvg } from "./images/svg/WhiteArrowSvg.svg";
import { ReactComponent as BlackArrowSvg } from "./images/svg/BlackArrowSvg.svg";
import { ReactComponent as WhiteSvg } from "./images/svg/WhiteSvg.svg";
import { ReactComponent as BlackSvg } from "./images/svg/BlackSvg.svg";
import { ReactComponent as UploadImageMemory } from "./images/svg/UploadImageMemory.svg";
import { ReactComponent as UploadImageCelebration } from "./images/svg/UploadImageCelebration.svg";
import { ReactComponent as ClappingMemorySvg } from "./images/svg/ClappingMemorySvg.svg";
import { ReactComponent as ClappingCelebrationSvg } from "./images/svg/ClappingCelebrationSvg.svg";
import { ReactComponent as PaperPlaneCelebrationSvg } from "./images/svg/PaperPlaneCelebrationSvg.svg";
import { ReactComponent as PaperPlaneMemorySvg } from "./images/svg/PaperPlaneMemorySvg.svg";
import { ReactComponent as ArrowDown } from "./images/svg/arrowDown.svg";
import { ReactComponent as ProfilePropertyMemory } from "./images/svg/ProfilePropertyMemory.svg";
import { ReactComponent as noCreatorSvg } from "./images/svg/noCreatorSvg.svg";
import { ReactComponent as HeartSvg } from "./images/svg/HeartSvg.svg";
import { ReactComponent as SortSvg } from "./images/svg/sortArrow.svg";
import { ReactComponent as Transfers } from "./images/svg/transfers.svg";
import { ReactComponent as PauseCircleSvg } from "./images/svg/PauseCircleSvg.svg";
import { ReactComponent as DeleteCharity } from "./images/svg/deleteCharity.svg";
import { ReactComponent as DeleteCharityMemory } from "./images/svg/DeleteCharityMemory.svg";
import { ReactComponent as RedTrashFill } from "./images/svg/redTrashFill.svg";
import { ReactComponent as ConvertCard } from "./images/svg/convertCard.svg";
import { ReactComponent as ErrorCircle } from "./images/svg/errorCircle.svg";
import { ReactComponent as ConvertCardGreen } from "./images/svg/convertCardGreen.svg";
import { ReactComponent as Refresh3Svg } from "./images/svg/Refresh3Svg.svg";
import { ReactComponent as CloseErrorSvg } from "./images/svg/CloseErrorSvg.svg";
import { ReactComponent as PaymentSuccess } from "./images/svg/paymentSuccess.svg";
import { ReactComponent as AccountDetailsSvg } from "./images/svg/AccountDetailsSvg.svg";
import { ReactComponent as AssetSvg } from "./images/svg/AssetSvg.svg";
import { ReactComponent as Warning1Svg } from "./images/svg/Warning1Svg.svg";
import { ReactComponent as CalendarSvg } from "./images/svg/CalendarSvg.svg";

import Sort from "./images/svg/sortArrow.svg";
import transformFrame from "./images/img/transformFrame.png";
import extraFrame from "./images/img/extraFrame.png";
import needCardFrame from "./images/img/needCardFrame.png";
import needCardFrameWhite from "./images/img/needCardFrameWhite.png";
import visaImg from "./images/img/visaImg.png";
import ameriaImg from "./images/img/ameriaImg.png";
import masterCardImg from "./images/img/masterCardImg.png";
import googlePayImg from "./images/img/googlePayImg.png";
import applePayImg from "./images/img/applePayImg.png";
import joinImage from "./images/img/joinImage.png";
import defCharity from "./images/img/defCharity.png";

export const Images = {
  needCardFrameWhite,
  joinImage,
  visaImg,
  ameriaImg,
  masterCardImg,
  googlePayImg,
  applePayImg,
  transformFrame,
  extraFrame,
  needCardFrame,
  defCharity,
};

export const Svg = {
  CalendarSvg,
  Warning1Svg,
  AssetSvg,
  AccountDetailsSvg,
  PaymentSuccess,
  ConvertCardGreen,
  ErrorCircle,
  ConvertCard,
  CloseErrorSvg,
  Refresh3Svg,
  NotYetMemory,
  RedTrashFill,
  DeleteCharity,
  DeleteCharityMemory,
  Transfers,
  PauseCircleSvg,
  SortSvg,
  HeartSvg,
  noCreatorSvg,
  ProfilePropertyMemory,
  ClappingMemorySvg,
  ClappingCelebrationSvg,
  PaperPlaneCelebrationSvg,
  PaperPlaneMemorySvg,
  UploadImageMemory,
  UploadImageCelebration,
  InCelebration,
  InMemorySvg,
  BlackSvg,
  WhiteSvg,
  WhiteArrowSvg,
  BlackArrowSvg,
  NotFoundSvg,
  CheckMarkSvg,
  SearchSvg,
  EnvelopeSvg,
  LockSvg,
  TrustedSvg,
  EthicsSvg,
  ShieldSvg,
  CreateEventSvg,
  CreateFreeSvg,
  FindAnEventSvg,
  ParticipateSvg,
  SelectsCharitiesSvg,
  ShareEventSvg,
  CheckboxNotChecked,
  CheckBoxChecked,
  ImageUpload,
  ConnectedBank,
  Ellipse,
  Info,
  CreditCard,
  EmailWhite,
  PhoneWhiteSvg,
  ShareTransparent,
  ArrowRightBlack,
  RecentDonors,
  CopyLink,
  FacebookShare,
  InstagramShare,
  LinkedinShare,
  TwitterShare,
  Bank,
  AppleSvg,
  Google,
  FundraiserFrameSvg,
  FundraiserFrameMemorySvg,
  NotYet,
  PublishAsset,
  PlayCircle,
  RedTrash,
  ShareIcon,
  Share,
  EditBlueSvg,
  ChevronRight,
  NotUploaded,
  Trash,
  Edit,
  BeckArrow,
  NoUserImage,
  NoFundraiserImage,
  Sort,
  Search,
  ProfileLink,
  FundraisersLink,
  SettingLink,
  CharitiesLink,
  Logout,
  ProfileProperty,
  ArrowDown,
  WelcomeAboard,
  Call,
  Profile,
  Welcome,
  CheckEmail,
  Recover,
  Lock,
  Sms,
  SignIn,
  SignInMemory,
  Close,
  FooterPhone,
  FooterEmail,
  Instagram,
  Facebook,
  Twitter,
  Youtube,
  DoneOlive,
  AddBlackCircle,
  AddWhiteCircle,
  Menu,
  ArrowRight,
  Heart,
};
