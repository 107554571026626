import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { CheckboxMain, SearchInput } from "components";
import { SeeYourEvent } from "./seeyourEvent";
import { CharityDetails } from "./charityDetails";
import { Stepper } from "./stepper";
import { CELEBRATION } from "./constants";
import { fundraiserService } from "../../../store/fundraiser/fundraiser.service";
import { UPLOAD_FUNDRAISER_IMAGE } from "../../fundraisers/fragments/constants";
import { charityActions, httpRequestsOnLoadActions } from "store";
import { Images } from "assets";
import { FindLoad, SaveParams } from "utils";

export const ChooseCharity = () => {
  const [showCharityDetailsAndSuccess, toggleShowing] = useState({
    showCharityDetails: false,
    successCratingEvent: false,
    successCratingCharity: false,
  });

  const location = useLocation();
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const uploadLoading = FindLoad(UPLOAD_FUNDRAISER_IMAGE);

  const { charities } = useSelector((state) => ({
    charities: state?.charity?.charities?.charities,
  }));

  const { handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      charities: [],
    },
  });
  const sandCharities = watch("charities");
  const info = location?.state;

  useEffect(() => {
    if (info?.charities) {
      setValue("charities", info?.charities);
    }
  }, []);

  useEffect(() => {
    const params = {
      status: "ACTIVE",
    };
    if (info?.name) {
      params.name = info.name;
    }

    dispatch(charityActions.getCharities(params));
  }, [dispatch, info]);

  const handleCreate = async (data) => {
    const images = info?.celebrationDetails?.images;
    const endDate = new Date(info?.celebrationDetails?.endDate);
    endDate.setHours(23, 59, 59, 999);
    const celebrationDate = new Date(info?.celebration?.celebrationDate);
    celebrationDate.setHours(23, 59, 59, 999);

    if (data.charities.length) {
      const sendData = {
        ...info?.celebration,
        celebrationDate,
        name: info?.celebrationDetails?.name,
        description: info?.celebrationDetails?.description,
        endDate,
        mainImage: info?.celebrationDetails?.mainImage,
        charities: [...data.charities],
        type: CELEBRATION,
      };

      if (info?.keepPercent === "100") {
        sendData.keepPercent = info?.keepPercent;
      } else {
        sendData.keepPercent = `${100 - +info?.splitPercent}`;
      }

      setLoading(true);

      if (info?.celebrationDetails?.images.length > 0) {
        const formData = new FormData();
        const endpoint = `/files/uploadMany`;
        images?.length && images.map((i) => formData.append("files", i));
        try {
          dispatch(
            httpRequestsOnLoadActions.appendLoading(UPLOAD_FUNDRAISER_IMAGE)
          );
          const res = await axios.post(endpoint, formData, { auth: true });
          sendData.images = res?.data;
        } catch (e) {
          SaveParams(location?.pathname, navigator, {
            ...info,
            step: 2,
          });
          dispatch(
            httpRequestsOnLoadActions.removeLoading(UPLOAD_FUNDRAISER_IMAGE)
          );
        } finally {
          dispatch(
            httpRequestsOnLoadActions.removeLoading(UPLOAD_FUNDRAISER_IMAGE)
          );
        }
      }

      fundraiserService
        .createFundraiserService(sendData)
        .then(() => {
          // navigator(`/event/${res?.data?.id}`);
          toggleShowing((prev) => ({
            ...prev,
            successCrating: true,
          }));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });

      const params = {
        ...info,
      };

      params.charities = data?.charities;
      SaveParams(location?.pathname, navigator, params);
    } else {
      setLoading(false);
      toast.warning("Please select one or more charities.");
    }
  };

  // const reqNewCharity = () => {
  //   toggleShowing((prev) => ({
  //     ...prev,
  //     showCharityDetails: true,
  //   }));
  // };

  const changeSendCharity = (id) => {
    if (!sandCharities?.includes(id)) {
      setValue("charities", [...sandCharities, id]);
    } else {
      setValue(
        "charities",
        sandCharities.filter((i) => i !== id)
      );
    }
  };

  return (
    <>
      {!showCharityDetailsAndSuccess.showCharityDetails ? (
        !showCharityDetailsAndSuccess.successCrating ? (
          <form
            className="in-celebration-box"
            onSubmit={handleSubmit(handleCreate)}
          >
            <div className="in-celebration-content">
              <div className="container">
                <div className="in-celebration-form-wrapper choose-charity-celebration-wrapper">
                  <div className="in-celebration-form-header">
                    <p className="in-celebration-form-header-title">
                      Choose Charities to Support
                    </p>

                    {/* <p className="in-celebration-form-header-desc">
                      Explore a list of charities, read their descriptions, and
                      visit their official sites to choose where to donate.
                    </p> */}
                  </div>

                  <div className="in-celebration-form-content">
                    <SearchInput
                      placeholder={"Search..."}
                      searchKey={"name"}
                      className="choose-charity-search"
                    />

                    <div className="choose-charity-content">
                      {!!charities?.length &&
                        charities.map((data, index) => (
                          <div
                            key={index}
                            className="choose-charity-single-wrapper"
                          >
                            <div className="choose-charity-single">
                              <div className="img-title-block">
                                <div className="img-block">
                                  <img
                                    src={
                                      data?.mainImage
                                        ? data?.images[data?.mainImage]?.url
                                        : Images?.defCharity
                                    }
                                    alt="main"
                                  />
                                </div>
                                {data?.name && (
                                  <p className="choose-charity-title">
                                    {data?.name}
                                  </p>
                                )}
                              </div>

                              <div className="choose-charity-description-block">
                                {data.description && (
                                  <p className="choose-charity-description">
                                    {data.description}
                                  </p>
                                )}
                              </div>
                            </div>

                            <CheckboxMain
                              styles={{
                                color: "#34A853",
                                checkedColor: "#34A853",
                              }}
                              onChange={() => {
                                changeSendCharity(data?.id);
                              }}
                              checked={sandCharities?.includes(data?.id)}
                            />
                          </div>
                        ))}
                    </div>

                    {/*<div*/}
                    {/*  className="request-new-charity"*/}
                    {/*  onClick={reqNewCharity}*/}
                    {/*>*/}
                    {/*  <div className="request-new-charity_svg-title">*/}
                    {/*    <Svg.AddWhiteCircle />*/}
                    {/*    <p className="request-new-charity-title">*/}
                    {/*      Request New Charity*/}
                    {/*    </p>*/}
                    {/*  </div>*/}
                    {/*  <p className="request-new-charity-text">*/}
                    {/*    Please note that your charity will be reviewed, and the*/}
                    {/*    approval process may{" "}*/}
                    {/*    <span style={{ fontWeight: "500" }}>*/}
                    {/*      take up to two weeks*/}
                    {/*    </span>*/}
                    {/*    . During that time you can’t publish your event.*/}
                    {/*  </p>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
            <Stepper loading={uploadLoading || loading} />
          </form>
        ) : (
          <SeeYourEvent />
        )
      ) : (
        <CharityDetails
          showCharityDetailsAndSuccess={showCharityDetailsAndSuccess}
          toggleShowing={toggleShowing}
        />
      )}
    </>
  );
};
