import { Svg } from "assets";

export const BackBtn = ({ handleClick, className }) => {
  const classNames = ["back-btn"];
  if (className) classNames.push(className);

  return (
    <button
      type="button"
      className={classNames.join(" ")}
      onClick={handleClick}
    >
      <Svg.BeckArrow />
      <p>Back</p>
    </button>
  );
};
