import { NavLink } from "react-router-dom";
import { AboutPages, CardAccepted, SocialLinks } from "./fragments/constants";

export const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();

  return (
    <div className="footer-wrapper">
      <div className="footer-content">
        <div className="container2">
          <div className="footer-content-box">
            <p className="footer-icon">MYWAYFUND</p>

            <div className="footer-links">
              {AboutPages.map((i, j) => (
                <NavLink
                  className="footer-link"
                  to={i?.link}
                  target={"_blank"}
                  key={j}
                >
                  {i?.title}
                </NavLink>
              ))}
            </div>

            <div className="footer-social-box">
              {SocialLinks?.map((i, j) => (
                <NavLink to={i?.link} target={"_blank"} key={j}>
                  {i?.icon}
                </NavLink>
              ))}
            </div>

            <div className="card-accepted-wrapper-mobile">
              <p className="card-accepted-title">Payment methods accepted</p>

              <div className="card-accepted-box">
                {CardAccepted.map((i, j) => (
                  <div className="single-card-small" key={j}>
                    <img src={i.img} alt="cards" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-info">
        <div className="container2">
          <div className="footer-info-box">
            <p className="footer-info-text">
              MYWAYFUND ©{year}.All Rights Reserved.
            </p>

            <div className="card-accepted-wrapper">
              <p className="card-accepted-title">Payment method accepted</p>

              <div className="card-accepted-box">
                {CardAccepted.map((i, j) => (
                  <div key={j} className="single-card-small">
                    <img src={i.img} alt="cards" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
