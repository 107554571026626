import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import { FindLoad, SaveParams } from "utils";
import { CheckboxMain, SearchInput } from "components";
import { CharityDetails } from "./charityDetails";
import { SeeYourEvent } from "./seeyourEvent";
import { Stepper } from "./stepper";
import { fundraiserService } from "../../../store/fundraiser/fundraiser.service";
import { UPLOAD_FUNDRAISER_IMAGE } from "../../fundraisers/fragments/constants";
import { charityActions, httpRequestsOnLoadActions } from "store";
import { MEMORY } from "./constants";
import { Images } from "assets";

export const ChooseCharity = () => {
  const [showCharityDetailsAndSuccess, toggleShowing] = useState({
    showCharityDetails: false,
    successCratingEvent: false,
    successCratingCharity: false,
  });

  const location = useLocation();
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const uploadLoading = FindLoad(UPLOAD_FUNDRAISER_IMAGE);

  const { charities } = useSelector((state) => ({
    charities: state?.charity?.charities?.charities,
  }));
  const { handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      charities: [],
    },
  });
  const sandCharities = watch("charities");
  const info = location?.state;

  useEffect(() => {
    if (info?.charities) {
      setValue("charities", info?.charities);
    }
  }, []);

  useEffect(() => {
    const params = {
      status: "ACTIVE",
    };
    if (info?.name) {
      params.name = info.name;
    }

    dispatch(charityActions.getCharities(params));
  }, [dispatch, info]);

  const handleCreate = async (data) => {
    const images = info?.deceasedDetails?.images;

    const endDate = new Date(info?.deceasedDetails?.endDate);
    endDate.setHours(23, 59, 59, 999);

    const birthDate = new Date(info?.deceased?.birthDate);
    birthDate.setHours(23, 59, 59, 999);

    const deathDate = new Date(info?.deceased?.deathDate);
    deathDate.setHours(23, 59, 59, 999);

    if (data?.charities?.length) {
      const sendData = {
        ...info?.deceased,
        endDate,
        birthDate,
        deathDate,
        name: info?.deceasedDetails?.name,
        description: info?.deceasedDetails?.description,
        mainImage: info?.deceasedDetails?.mainImage,
        charities: [...data.charities],
        type: MEMORY,
        keepPercent: info?.keepPercent,
      };

      if (info?.deceasedDetails?.images.length > 0) {
        const formData = new FormData();
        const endpoint = `/files/uploadMany`;
        images?.length && images.map((i) => formData.append("files", i));
        try {
          dispatch(
            httpRequestsOnLoadActions.appendLoading(UPLOAD_FUNDRAISER_IMAGE)
          );
          const res = await axios.post(endpoint, formData, { auth: true });
          sendData.images = res?.data;
        } catch (e) {
          SaveParams(location?.pathname, navigator, {
            ...info,
            step: 2,
          });
          dispatch(
            httpRequestsOnLoadActions.removeLoading(UPLOAD_FUNDRAISER_IMAGE)
          );
        } finally {
          dispatch(
            httpRequestsOnLoadActions.removeLoading(UPLOAD_FUNDRAISER_IMAGE)
          );
        }
      }

      setLoading(true);
      fundraiserService
        .createFundraiserService(sendData)
        .then(() => {
          // navigator(`/event/${res?.data?.id}`);

          toggleShowing((prev) => ({
            ...prev,
            successCrating: true,
          }));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });

      const params = {
        ...info,
      };

      params.charities = data?.charities;
      SaveParams(location?.pathname, navigator, params);
    } else {
      setLoading(false);
      toast.warning("Please select one or more charities.");
    }
  };

  // const reqNewCharity = () => {
  //   toggleShowing((prev) => ({
  //     ...prev,
  //     showCharityDetails: true,
  //   }));
  // };

  const changeSendCharity = (id) => {
    if (!sandCharities?.includes(id)) {
      setValue("charities", [...sandCharities, id]);
    } else {
      setValue(
        "charities",
        sandCharities.filter((i) => i !== id)
      );
    }
  };

  return (
    <>
      {!showCharityDetailsAndSuccess.showCharityDetails ? (
        !showCharityDetailsAndSuccess.successCrating ? (
          <form
            className="in-celebration-box"
            onSubmit={handleSubmit(handleCreate)}
          >
            <div className="in-celebration-content">
              <div className="container">
                <div className="in-celebration-form-wrapper choose-charity-celebration-wrapper">
                  <div className="in-celebration-form-header">
                    <p className="in-celebration-form-header-title">
                      Choose Charities to Support
                    </p>

                    {/* <p className="in-celebration-form-header-desc">
                      Explore a list of charities, read their descriptions, and
                      visit their official sites to choose where to donate.
                    </p> */}
                  </div>

                  <div className="in-celebration-form-content">
                    <SearchInput
                      placeholder={"Search..."}
                      searchKey={"name"}
                      className="choose-charity-search"
                    />

                    <div className="choose-charity-content">
                      {!!charities?.length &&
                        charities.map((data, index) => (
                          <div
                            key={index}
                            className="choose-charity-single-wrapper"
                          >
                            <div className="choose-charity-single">
                              <div className="img-title-block">
                                <div className="img-block">
                                  <img
                                    src={
                                      data?.mainImage
                                        ? data?.images[data?.mainImage]?.url
                                        : Images?.defCharity
                                    }
                                    alt="main"
                                  />
                                </div>
                                {data?.name && (
                                  <p className="choose-charity-title">
                                    {data?.name}
                                  </p>
                                )}
                              </div>

                              <div className="choose-charity-description-block">
                                {data.description && (
                                  <p className="choose-charity-description">
                                    {data.description}
                                  </p>
                                )}
                              </div>
                            </div>

                            <CheckboxMain
                              styles={{
                                color: "#34A853",
                                checkedColor: "#34A853",
                              }}
                              onChange={() => {
                                changeSendCharity(data?.id);
                              }}
                              checked={sandCharities?.includes(data?.id)}
                            />
                          </div>
                        ))}
                    </div>

                    {/*<div*/}
                    {/*  className="request-new-charity"*/}
                    {/*  onClick={reqNewCharity}*/}
                    {/*>*/}
                    {/*  <div className="request-new-charity_svg-title">*/}
                    {/*    <Svg.AddWhiteCircle />*/}
                    {/*    <p className="request-new-charity-title">*/}
                    {/*      Request New Charity*/}
                    {/*    </p>*/}
                    {/*  </div>*/}
                    {/*  <p className="request-new-charity-text">*/}
                    {/*    Please provide the requested charity information to send*/}
                    {/*    for review by the admin.*/}
                    {/*  </p>*/}
                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
            <Stepper loading={uploadLoading || loading} />
          </form>
        ) : (
          <SeeYourEvent />
        )
      ) : (
        <CharityDetails
          showCharityDetailsAndSuccess={showCharityDetailsAndSuccess}
          toggleShowing={toggleShowing}
        />
      )}
    </>
  );
};
