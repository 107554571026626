import React from "react";
import { Svg } from "assets";
import { ButtonMain } from "components";
import { useNavigate } from "react-router";

export const SeeYourEvent = () => {
  const navigate = useNavigate();

  const goToPage = () => {
    navigate(`/events`, { replace: true });
  };

  return (
    <div className="in-celebration-box">
      <div className="in-celebration-content">
        <div className="container">
          <div className="in-celebration-form-wrapper see-your-event-wrapper">
            <Svg.ClappingCelebrationSvg />
            <div className="see-your-event-title_desc">
              <p className="see-your-event-title">
                Well Done! Your Event Successfully have created.
              </p>
              <p className="see-your-event-desc">
                Your event is ready to go. You can now manage your event details
                and make any necessary updates. Thank you for organizing this
                event with us!
              </p>
            </div>

            <ButtonMain
              classNameWrapper="celebration-button"
              text={"View Event"}
              type="button"
              onClick={goToPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
