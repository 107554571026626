import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import axios from "axios";
import { CheckboxMain, NumericFormatCustom, RadioMain } from "components";
import { generateDisplayId, SaveParams } from "utils";
import { Stepper } from "./stepper";
import { RadioGroup } from "@mui/material";
import { CELEBRATION, percents } from "./constants";
import { fundraiserService } from "../../../store/fundraiser/fundraiser.service";
import { httpRequestsOnLoadActions } from "store";
import { UPLOAD_FUNDRAISER_IMAGE } from "../../fundraisers/fragments/constants";
import { Svg } from "assets";

export const DistributeFunds = () => {
  const [iAgree, setIAgree] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showOtherPercent, setShowOtherPercent] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const info = location?.state;

  const { handleSubmit, control, setValue, watch } = useForm({
    defaultValues: {
      splitPercent: "5",
      keepPercent: "0",
    },
  });

  const splitPercent = watch("splitPercent");
  const keepPercent = watch("keepPercent");

  useEffect(() => {
    if (info?.splitPercent || info?.keepPercent) {
      setValue("splitPercent", info?.splitPercent);
      setValue("keepPercent", info?.keepPercent);
    }
  }, [info]);

  const handleCreate = async (data) => {
    const images = info?.celebrationDetails?.images;
    const params = {
      ...info,
      splitPercent: data?.splitPercent,
      keepPercent: data?.keepPercent,
    };

    const sendData = {
      ...info?.celebration,
      ...info?.celebrationDetails,
      charities: [],
      type: CELEBRATION,
    };

    if (data?.keepPercent === "100") {
      sendData.keepPercent = data?.keepPercent;
    } else {
      sendData.keepPercent = `${100 - +data?.splitPercent}`;
    }
    if (!iAgree) {
      setShowWarning(true);
    } else if (data?.keepPercent !== "100") {
      params.step = 5;
    } else {
      setLoading(true);

      if (info?.celebrationDetails?.images.length > 0) {
        const formData = new FormData();
        const endpoint = `/files/uploadMany`;
        images?.length && images.map((i) => formData.append("files", i));
        try {
          dispatch(
            httpRequestsOnLoadActions.appendLoading(UPLOAD_FUNDRAISER_IMAGE)
          );
          const res = await axios.post(endpoint, formData, { auth: true });
          sendData.images = res?.data;
        } catch (e) {
          SaveParams(location?.pathname, navigator, {
            ...info,
            step: 2,
          });
          dispatch(
            httpRequestsOnLoadActions.removeLoading(UPLOAD_FUNDRAISER_IMAGE)
          );
        } finally {
          dispatch(
            httpRequestsOnLoadActions.removeLoading(UPLOAD_FUNDRAISER_IMAGE)
          );
        }
      }

      fundraiserService
        .createFundraiserService(sendData)
        .then((res) => {
          navigator(`/event/${res?.data?.id}`);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    // if (myProfile?.pmtAccount) {
    //   if (!myProfile?.pmtAccount?.bankId) {
    //     params.step = 6;
    //   } else if (data?.keepPercent !== "100") {
    //     params.step = 7;
    //   } else {
    //     setLoading(true);
    //     fundraiserService
    //       .createFundraiserService(sendData)
    //       .then((res) => {
    //         navigator(`/event/${res?.data?.id}`);
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //       })
    //       .finally(() => {
    //         setLoading(false);
    //       });
    //   }
    // } else {
    //   if (ifAdmin) {
    //     params.step = 7;
    //   } else {
    //     params.step = 5;
    //   }
    // }

    SaveParams(location?.pathname, navigator, params);
  };

  const changePercent = (value) => {
    setValue("splitPercent", value);
    setValue("keepPercent", "0");
  };

  return (
    <form className="in-celebration-box" onSubmit={handleSubmit(handleCreate)}>
      <div className="in-celebration-content">
        <div className="container">
          <div className="in-celebration-form-wrapper distribute-wrapper-celebration">
            <div className="in-celebration-form-header">
              <p className="in-celebration-form-header-title">
                Choose How You’d Like to Split the Funds
              </p>

              {/* <p className="in-celebration-form-header-desc">
                Select the option that best suits your event goals.
              </p> */}
            </div>

            <div className="in-celebration-form-content">
              <div className="chips-and-info-wrapper">
                <p className="chips-and-info-title">
                  Event ID: {generateDisplayId(info.celebration)}
                </p>
              </div>

              <RadioGroup>
                <div className="distribute-fund-types-block">
                  {showWarning && !iAgree && (
                    <div className="warning_split_agree">
                      <Svg.Warning1Svg />

                      <p className="warning_split_agree-text">
                        Please, agree to a 5% platform fee from all
                        contributions to proceed.
                      </p>
                    </div>
                  )}

                  <div className="distribute-fund-types-single">
                    <RadioMain
                      control={control}
                      name="keepPercent"
                      value="0"
                      styles={{
                        color: "#34A853",
                        checkedColor: "#34A853",
                      }}
                    />
                    <div className="distribute-fund-types_percents">
                      <div className="distribute-fund-types-text_desc">
                        <p className="distribute-fund-types-text">
                          Split Funds
                        </p>

                        <p className="distribute-fund-types-desc">
                          Select the percentage of funds you would like to
                          donate to charities. The remaining funds will be kept
                          by you. You will need to provide your banking info and
                          select charities in the next steps.
                        </p>
                      </div>

                      <div className="distribute-fund_percents">
                        {percents.map(({ label, value }) => (
                          <div
                            className={`distribute-fund_single-percent ${
                              +splitPercent === +value
                                ? "single-percent_active"
                                : ""
                            }`}
                            key={value}
                            onClick={() => {
                              setValue("splitPercent", value);
                              setValue("keepPercent", "0");
                              setShowOtherPercent(false);
                            }}
                          >
                            {label}
                          </div>
                        ))}
                      </div>

                      <div className="distribute-fund-other_percent">
                        {showOtherPercent ? (
                          <div className="other_percent-mask-wrapper">
                            <NumericFormatCustom
                              classNameWrapper="other_percent-input"
                              placeholder="1"
                              name="splitPercent"
                              allowNegative={false}
                              showError={false}
                              decimalScale={0}
                              control={control}
                              handleChange={changePercent}
                              isAllowed={(formats) => {
                                const { floatValue } = formats;
                                return (
                                  floatValue === undefined ||
                                  (floatValue >= 1 && floatValue <= 100)
                                );
                              }}
                            />
                            <div className="percent">%</div>
                          </div>
                        ) : (
                          <div
                            className="distribute-fund-other_percent-text"
                            onClick={() => setShowOtherPercent(true)}
                          >
                            Other
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="distribute-fund-types_percents">
                    <div className="distribute-fund-types-single">
                      <RadioMain
                        onClick={() => setShowOtherPercent(false)}
                        control={control}
                        name="keepPercent"
                        value="100"
                        styles={{
                          color: "#34A853",
                          checkedColor: "#34A853",
                        }}
                      />

                      <div className="distribute-fund-types_percents">
                        <div className="distribute-fund-types-text_desc">
                          <p className="distribute-fund-types-text">
                            Keep 100%
                          </p>

                          <p className="distribute-fund-types-desc">
                            In the next step, please provide your banking info
                            to receive the event funds.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <CheckboxMain
                    checked={iAgree}
                    onChange={() => setIAgree((prevState) => !prevState)}
                    label="I agree to a 30c+5% platform fee from all contributions."
                    styles={{
                      color: "#34A853",
                      checkedColor: "#34A853",
                      labelColor: "#000",
                    }}
                  />
                </div>
              </RadioGroup>
            </div>
          </div>
        </div>
      </div>
      <Stepper loading={loading} isCompleted={keepPercent === "100"} />
    </form>
  );
};
