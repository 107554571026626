import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { DateCalendarForm, InputMain } from "components";
import { SaveParams } from "utils";
import { Stepper } from "./stepper";

export const DeceasedInformation = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const info = location?.state;
  const { handleSubmit, control, reset, watch } = useForm();
  const todayDate = moment().format("YYYY-MM-DD");

  useEffect(() => {
    if (info?.deceased) {
      reset(info?.deceased);
    }
  }, [info]);

  const handleCreate = (data) => {
    const params = {
      ...info,
      step: 2,
    };

    params.deceased = {
      ...data,
    };
    SaveParams(location?.pathname, navigator, params);
  };
  return (
    <form className="in-celebration-box" onSubmit={handleSubmit(handleCreate)}>
      <div className="in-celebration-content">
        <div className="container">
          <div className="in-celebration-form-wrapper deceased-information-wrapper">
            <div className="in-celebration-form-header">
              <p className="in-celebration-form-header-title">
                Deceased Information
              </p>
            </div>
            <div className="in-celebration-form-content">
              <InputMain
                className="celebration-input"
                name="firstName"
                control={control}
                placeholder="ex. Richard"
                labelHeader="First Name of the Deceased"
                rules={{
                  required: "Deceased first name is required",
                  validate: {
                    trimmed: (value) =>
                      value.trim() !== "" ||
                      "Deceased first name cannot be empty or just spaces",
                  },
                }}
              />

              <InputMain
                className="celebration-input"
                name="lastName"
                control={control}
                placeholder="ex. Smith"
                labelHeader="Last Name of the Deceased"
                rules={{
                  required: "Deceased last name is required",
                  validate: {
                    trimmed: (value) =>
                      value.trim() !== "" ||
                      "Deceased last name cannot be empty or just spaces",
                  },
                }}
              />

              <InputMain
                className="celebration-input"
                name="middleName"
                control={control}
                placeholder="ex. R.S"
                labelHeader="Middle Name of the Deceased"
              />

              <InputMain
                className="celebration-input"
                name="relationship"
                control={control}
                placeholder="ex. son"
                labelHeader="Relationship to the Deceased"
                rules={{
                  required: "Relationship is required",
                  validate: {
                    trimmed: (value) =>
                      value.trim() !== "" ||
                      "Relationship cannot be empty or just spaces",
                  },
                }}
              />

              <div className="date-of-birth_death">
                <DateCalendarForm
                  className="celebration-input"
                  name="birthDate"
                  label="Date of Birth*"
                  placeholder="Choose Date"
                  control={control}
                  max={todayDate}
                  rules={{
                    required: "Date of birth is required",
                  }}
                />

                <DateCalendarForm
                  className="celebration-input"
                  name="deathDate"
                  control={control}
                  placeholder="Choose Date"
                  label="Date of Death*"
                  min={watch("birthDate")}
                  max={todayDate}
                  rules={{
                    required: "Date of death is required",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Stepper />
    </form>
  );
};
