import React from "react";
import { Loader } from "components";
import { FindLoad, useWindowDimensions } from "utils";

export const ButtonMain = ({
  classNameWrapper,
  text,
  disabled,
  actionTye,
  type = "button",
  onClick,
  name,
  startIcon,
  endIcon,
  mobileBtnText,
  load,
  loadingColor,
  wrapperStyles,
  buttonMainStyles,
  textStyles,
  ref,
}) => {
  const loading = FindLoad(actionTye);
  const { width } = useWindowDimensions();
  const checkMobileButtonText = mobileBtnText === "notShow" && width < 768;

  const buttonClasses = ["main-button"];
  if (classNameWrapper) buttonClasses.push(classNameWrapper);

  return (
    <button
      ref={ref}
      name={name}
      className={buttonClasses.join(" ")}
      type={type ? type : "button"}
      disabled={disabled || loading || load}
      onClick={onClick}
      style={{ ...buttonMainStyles }}
    >
      {loading || load ? (
        <Loader styles={{ color: loadingColor ? loadingColor : "#FFF" }} />
      ) : (
        <div className="flex-all-center" style={{ ...wrapperStyles }}>
          {startIcon && (
            <div className="button-start-adornment">{startIcon}</div>
          )}
          {!checkMobileButtonText && <p style={{ ...textStyles }}>{text}</p>}
          {endIcon && <div className="button-end-adornment">{endIcon}</div>}
        </div>
      )}
    </button>
  );
};
