import React from "react";
import { HeaderDrawerMain, PriceRow } from "components";
import { FundraiserDetailsCard } from "../fundraiserDetailsCard";
import { ProfileInfo } from "utils";

export const DetailDonationsModal = ({ item, closeDrawer }) => {
  const { ifAdmin } = ProfileInfo();

  return (
    <div>
      <HeaderDrawerMain title="Transfer Details" closeModal={closeDrawer} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "16px",
        }}
      >
        {item?.charities?.map((i, k) => (
          <FundraiserDetailsCard
            headerColor="#F4F4F4"
            title={i?.charityName}
            list={[
              {
                name: "Net Amount",
                description: <PriceRow info={+i?.netAmount} />,
              },

              {
                name: "Service Fee",
                description: <PriceRow info={+i?.serviceFee} />,
              },
            ]}
            key={k}
          />
        ))}
        {ifAdmin ? (
          <FundraiserDetailsCard
            headerColor={"#DBEDDB"}
            titleColor={"#1C212D"}
            borderColor={"#E0E1E2"}
            title="Owner part"
            list={[
              {
                name: "Net Amount",
                description: <PriceRow info={+item?.keepAmount?.netAmount} />,
              },
            ]}
          />
        ) : (
          <FundraiserDetailsCard
            headerColor={"#DBEDDB"}
            titleColor={"#1C212D"}
            borderColor={"#E0E1E2"}
            title="Owner part"
            list={[
              {
                name: "Net Amount",
                description: <PriceRow info={+item?.keepAmount?.netAmount} />,
              },

              {
                name: "Service Fee",
                description: <PriceRow info={+item?.keepAmount?.serviceFee} />,
              },
            ]}
          />
        )}
      </div>
    </div>
  );
};
