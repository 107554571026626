export const PrivacyPolicy = () => {
  return (
    <div className="privacy-page">
      <p className="privacy-title">Privacy Policy</p>
      <div className="privacy-box">
        <p className="bold-text">
          Please read these terms and conditions carefully before using Our
          Service.
        </p>
        <br />

        <div className="flex-gap10">
          <p className="bold-text">
            1. Introduction:{" "}
            <span className="main-text">
              At MyWayFund.com ("we," "us," or "our"), we are committed to
              protecting the privacy of our users. This Privacy Policy explains
              how we collect, use, disclose, and safeguard your information when
              you visit our website or use our services. By accessing or using
              MyWayFund, you agree to this Privacy Policy. If you do not agree,
              please do not use our website.
            </span>
          </p>

          <p className="bold-text">2.Information We Collect</p>

          <p className="bold-text">
            Personal Information:{" "}
            <span className="main-text">
              When you register on the site, create a fundraising campaign, or
              donate, we may collect personal information, including but not
              limited to:
            </span>
          </p>

          <ul className="sub_list-box" style={{ paddingLeft: 30 }}>
            <li className="main-text">Name</li>
            <li className="main-text">Email address</li>
            <li className="main-text">Phone number</li>
            <li className="main-text">
              Payment information (collected by third-party payment processors)
            </li>
            <li className="main-text">
              Shipping or mailing address (if applicable)
            </li>
          </ul>

          <p className="bold-text">
            Non-Personal Information:{" "}
            <span className="main-text">
              We may also collect non-personal information, such as:
            </span>
          </p>

          <ul className="sub_list-box" style={{ paddingLeft: 30 }}>
            <li className="main-text">Browser type and version</li>
            <li className="main-text">Operating system</li>
            <li className="main-text">IP address</li>
            <li className="main-text">Referring website</li>
            <li className="main-text">Date and time of access</li>
          </ul>

          <p className="main-text">
            This information is used to help us understand how visitors use the
            site, improve our services, and prevent fraudulent activities.
          </p>

          <p className="bold-text">
            3. How We Use Your Information:{" "}
            <span className="main-text">
              We may use the information we collect for the following purposes:
            </span>
          </p>

          <ul className="sub_list-box">
            <li className="main-text">
              To operate and manage the website and services.
            </li>
            <li className="main-text">To process payments and donations.</li>
            <li className="main-text">
              To verify the identity of users and monitor for fraudulent
              activity.
            </li>
            <li className="main-text">
              To send important notices such as updates, confirmations, and
              changes to our policies.
            </li>
            <li className="main-text">
              To communicate with users about their accounts, campaigns, or
              donations.
            </li>

            <li className="main-text">
              To comply with legal obligations and enforce our terms of service.
            </li>
          </ul>

          <p className="bold-text">4. Sharing Your Information</p>

          <p className="bold-text">
            Third-Party Service Providers:{" "}
            <span className="main-text">
              We may share your information with third-party service providers
              who help us manage the site, process payments, or provide other
              services necessary for operating the platform. Examples include:
            </span>
          </p>

          <ul className="sub_list-box">
            <li className="main-text">Payment processors</li>
            <li className="main-text">Website hosting providers</li>
            <li className="main-text">Email service providers</li>
          </ul>

          <p className="main-text">
            These providers are authorized to use your personal information only
            as necessary to provide their services.
          </p>

          <p className="bold-text">
            Legal Requirements:{" "}
            <span className="main-text">
              We may disclose your information if required to do so by law or in
              response to valid requests by public authorities (e.g., a court or
              a government agency).
            </span>
          </p>

          <p className="bold-text">
            Business Transfers:{" "}
            <span className="main-text">
              In the event of a merger, acquisition, or sale of all or a portion
              of our assets, your personal information may be part of the
              transferred assets.
            </span>
          </p>

          <p className="bold-text">
            5.Cookies and Tracking Technologies:{" "}
            <span className="main-text">
              We may use cookies and similar tracking technologies to:
            </span>
          </p>

          <ul className="sub_list-box">
            <li className="main-text">Provide and improve our services.</li>
            <li className="main-text">Remember your preferences.</li>
            <li className="main-text">
              Track how users interact with the website.
            </li>
          </ul>

          <p className="main-text">
            You can modify your browser settings to refuse cookies; however,
            doing so may affect your ability to use certain features of our
            site.
          </p>

          <p className="bold-text">
            6.Data Retention:{" "}
            <span className="main-text">
              We retain personal information for as long as necessary to provide
              our services, comply with legal obligations, resolve disputes, and
              enforce our agreements. When we no longer need your information,
              we will securely delete or anonymize it.
            </span>
          </p>

          <p className="bold-text">
            7.Security:{" "}
            <span className="main-text">
              We take the security of your information seriously and use
              reasonable technical, administrative, and physical safeguards to
              protect against unauthorized access, disclosure, or misuse.
              However, no method of transmission over the internet or electronic
              storage is 100% secure, and we cannot guarantee absolute security.
            </span>
          </p>

          <p className="bold-text">
            8.Your Rights:{" "}
            <span className="main-text">
              Depending on your jurisdiction, you may have the following rights
              regarding your personal information:
            </span>
          </p>

          <ul className="sub_list-box">
            <li className="main-text">
              <span className="bold-text">Access:</span> You can request access
              to the information we hold about you.
            </li>

            <li className="main-text">
              <span className="bold-text">Correction:</span> You can ask us to
              correct or update any inaccurate information.
            </li>

            <li className="main-text">
              <span className="bold-text">Deletion:</span> You can request that
              we delete your personal information, subject to certain legal
              obligations.
            </li>

            <li className="main-text">
              <span className="bold-text">Opt-Out:</span> You can opt-out of
              receiving marketing communications by following the unsubscribe
              link in our emails. obligations.
            </li>
          </ul>

          <p className="main-text">
            To exercise any of these rights, please contact us at
            info@mywayfund.com.
          </p>

          <p className="main-text">
            <span className="bold-text">9.Third-Party Links:</span> Our website
            may contain links to third-party websites. We are not responsible
            for the privacy practices or content of these websites. We encourage
            users to read the privacy policies of any third-party sites they
            visit.
          </p>

          <p className="main-text">
            <span className="bold-text">
              10.Changes to This Privacy Policy:
            </span>{" "}
            We may update this Privacy Policy from time to time. Any changes
            will be posted on this page with an updated revision date. Your
            continued use of the website after changes are made constitutes your
            acceptance of the updated policy.
          </p>

          <p className="main-text">
            <span className="bold-text">11. Contact Us</span> If you have any
            questions or concerns about this Privacy Policy, please contact us
            at info@mywayfund.com.
          </p>
        </div>
      </div>
    </div>
  );
};
